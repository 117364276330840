// have to import it here as well, otherwise can't read vars
@import "../public/theme.less";
@import "~antd/dist/antd.less";

html {
  // prevent blocking of scrolling when product tour breaks
  overflow: visible !important;
}

.hide {
  display: none !important;
}

@media (max-width: 480px) {
  .hide-xs {
    display: none !important;
  }
}

header.ant-layout-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 1px 2px 0px #1e001529;
  z-index: 2;

  @media (max-width: 480px) {
    padding: 0;
  }

  @media (max-width: 750px) {
    padding: 0 5px;
  }

  .header-right-menu {
    align-items: center;
    display: flex;
    max-width: 100%;

    @media (max-width: 480px) {
      width: 100%;
      justify-content: space-between;
    }

    .menu-item {
      display: flex;
      align-items: center;
      margin: 0 8px;

      &.hover:hover {
        opacity: 0.85;
      }
    }

    .ant-divider-vertical {
      height: 36px;
    }
  }
}

.rounder {
  // TODO: update @border-radius-base to 5px, when ready
  border-radius: 5px;
}

.ant-dropdown-menu {
  padding: 8px 0;

  // hack to fix https://www.pivotaltracker.com/story/show/175189383
  .ant-dropdown-menu-submenu-hidden {
    display: none;
  }

  .ant-dropdown-menu-item {
    padding: 8px 16px;
  }

  .inactive-dropdown-menu-item {
    cursor: auto;
    background-color: @white;
  }
}

.ant-dropdown-menu-item-icon {
  font-size: 14px;
}

.ant-drawer-content-wrapper {
  max-width: 100vw;
}

.ant-layout-sider {
  z-index: 4;
}

@media (max-width: 480px) {
  .ant-layout-sider {
    position: fixed;
    height: 100%;
    overflow-y: scroll;

    &.ant-layout-sider-collapsed {
      width: 1px !important;
      min-width: 0px !important;
      flex: 0 !important;

      .ant-layout-sider-children {
        display: none;
      }

      .ant-layout-sider-trigger {
        width: 25px !important;
        border-radius: 0px 15px 15px 0px;
        bottom: 5px;
      }
    }
  }

  .ant-card-body {
    padding: 16px;
  }
}

.row-disabled {
  opacity: 0.7;
  &:hover {
    opacity: 0.8;
  }

  .ant-table-cell {
    background: #f9f9f9;

    a {
      color: @black;
    }
  }
}

.row-total {
  background: #f9f9f9;
  font-weight: 700;
}

.list .ant-table-row {
  cursor: pointer;
}

.row-hidden-expand {
  .ant-table-row-expand-icon {
    visibility: hidden;
  }
}

a.text-link {
  color: inherit;

  &:hover {
    color: @link-hover-color;
  }
}

.icon-text {
  vertical-align: middle;
  margin-right: 8px;
}

.btn-complete {
  background-color: #1a173b;
  border-color: #1a173b;
}

.btn-yellow {
  background-color: #d5b323;
  border-color: #d5b323;
  &:hover,
  &:focus {
    background-color: #d46b08;
    border-color: #d46b08;
  }
}

.ant-tree {
  .ant-tree-switcher.ant-tree-switcher-noop {
    position: relative;

    .anticon-file {
      display: none;
    }

    &:after {
      content: "";
      display: inline-block;
      width: 8px;
      height: 8px;
      margin-left: 1px;
      background: #d1d8dd;
      border-radius: 50%;
    }
  }

  &.block {
    .ant-tree-treenode {
      width: 100%;

      .ant-tree-node-content-wrapper {
        width: 100%;
      }
    }
  }
}

.select-ingredients .ant-select-selection__choice {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
  .ant-select-selection__choice__remove {
    color: #52c41a;
  }
}

.ingredient-rate .ant-input-group-addon:first-child {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
  min-width: 150px;
}

.ant-input-group {
  .ant-input-group-addon + .ant-input-number {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .ant-input-number:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .ant-input-group-addon:not(:first-child) {
    border-left: none;
  }
}

.select-new-item {
  cursor: pointer;
  padding: 4px 8px;
  margin-bottom: 4px;
  color: @text-color;

  &:hover {
    color: @link-color;
  }
}

.details-descriptions {
  .ant-descriptions-item {
    padding-bottom: 3px;

    .ant-descriptions-item-content {
      flex-wrap: wrap;
      gap: 8px 0;
    }
  }
  .ant-descriptions-item-label {
    padding-top: 25px;
    font-weight: 700;
  }
}

.ant-steps-horizontal .ant-steps-item-description {
  max-width: none !important;
}

.ant-form-item.form-item-compact {
  margin: 0;
  .ant-form-item-explain {
    min-height: 0;
  }
  .ant-form-item-control-input {
    min-height: 0;
  }
}

.ant-form-item .ant-upload.ant-upload-drag {
  background-color: #ffffff;
  border: none;

  .ant-upload {
    padding: 0;
  }
}

.ant-form-horizontal-override .ant-form-item-row {
  flex-direction: row;
  align-items: center;

  .ant-form-item-label {
    padding: 0;
  }
}

.form-item-small .ant-form-item {
  margin-bottom: 16px;
}

.ant-upload.ant-upload-select-picture-card {
  margin: 0;
}

.ant-comment-content-author-time {
  flex: 1;
}

// Mentions
.ant-mentions:focus,
.ant-mentions-focused {
  outline: none;
  box-shadow: none;
}

.mention-email {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}

.ant-input-affix-wrapper {
  display: inline-flex !important;
}

.ant-select-borderless .ant-select-selector {
  padding-left: 0 !important;
}

.ant-select-auto .ant-select-selector {
  height: auto !important;
}

.ant-collapse-content {
  border-top: 1px solid #d9d9d9 !important;
}

.wizard {
  .ant-steps-item-title {
    font-size: 20px;
    line-height: 24px !important;
    width: 100%;
    .ant-steps-item-subtitle {
      float: right;
    }
  }
}

.sticky-bottom-panel {
  background: #ffffff;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  border-top: 1px solid #d9d9d9 !important;
  padding: 16px;
  z-index: 2;
}

.ant-input-affix-wrapper.ant-input-number-affix {
  padding: 0px;
  align-items: center;

  .ant-input-prefix {
    padding-left: 8px;
  }

  .ant-input-suffix {
    padding-right: 8px;
  }

  .ant-input-number {
    border: none;
  }

  .ant-input-number-focused {
    outline: none;
    box-shadow: none;
  }

  &.ant-input-number-disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
  }
}

.ant-collapse.ant-collapse-borderless > .ant-collapse-item.wizard-section {
  background: #fff;
  margin: 16px 0;

  &.wizard-section-hidden {
    border: 1px dashed;
    opacity: 0.5;

    &:hover {
      border-color: #40a9ff;
      color: #40a9ff;
      opacity: 1;
    }

    .ant-collapse-header {
      padding: 12px 16px;
      padding-right: 28px;
    }

    .ant-collapse-content {
      display: none;
    }
  }

  .ant-collapse-header-text {
    width: 100%;
  }

  .ant-collapse-arrow {
    top: 49%;
  }
}

.ant-drawer {
  .ant-drawer-header {
    position: absolute;
    top: 0;
    background: #fff;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    left: 0;
    z-index: 3;
  }

  &.sidebar-filters {
    .ant-drawer-content {
      background: #eff1f7;
    }

    .ant-drawer-header {
      background: #eff1f7;
      border-color: #d8d8d8;
    }
  }
}

.ant-radio-wrapper:last-child {
  margin-right: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.ant-table-wrapper.has-error-state {
  & .ant-table-container,
  .ant-table-title,
  td {
    border-color: @alert-error-border-color !important;
  }
}

.ant-table-wrapper.has-compact-title .ant-table-title {
  padding: 0;
}

.ant-table-bordered {
  .ant-table-row-expand-icon-cell {
    border-right: none !important;
  }

  .ant-table-selection-column {
    padding: 16px 20px;
  }

  .ant-table-row-expand-icon-cell + .ant-table-selection-column {
    padding: 0;
  }
}

td.ant-table-cell.muted {
  color: @text-color-secondary;
}

th.ant-table-cell {
  .ant-table-column-sorter {
    margin-left: 5px;
  }
}

.ant-table-summary {
  font-weight: bold;
}

.has-grey-background {
  background-color: @disabled-bg;
}

.ant-list-split .ant-list-header {
  margin-bottom: 8px;
}

.ant-list-item.clickable {
  cursor: pointer;
  &:hover {
    background-color: @disabled-bg;
  }
}

.ant-checkbox-wrapper {
  // prevent text selection on double-click on labels in checkbox
  user-select: none;
}

.ant-form-item-extra {
  min-height: auto;
}

.ant-empty-normal {
  margin: 0;
}

.page-header {
  background-color: #fff;
  padding: 16px;

  .ant-descriptions-row:last-child .ant-descriptions-item {
    padding-bottom: 0;
  }
}

.details-link {
  padding-right: 20px;
  background-position: right;
  background-repeat: no-repeat;

  &:hover {
    background-image: url(/images/icons/view.svg);
  }

  &.details-link-blank {
    &:hover {
      background-image: url(/images/icons/external-link.svg);
    }
  }
}

.top-page-link {
  font-size: 90%;
  opacity: 0.6;

  a {
    color: black;
  }

  &:hover {
    opacity: 1;
  }
}

.week-panel {
  .ant-picker-panel-container {
    padding-left: 24px;
  }

  .ant-picker-date-panel {
    tr:hover {
      .week-number {
        background: #518cbc;
      }
    }

    .week-number {
      position: absolute;
      left: -30px;
      border-radius: 5px;
      background: #40a9ff;
      color: #fff;
      width: 24px;
      height: 24px;
      font-size: 12px;

      &:empty {
        background: #eee !important;
      }
    }
  }
}

.ant-picker-cell-inner {
  &.non-working-date {
    background: #d1e2f3;
  }

  &.holiday-date {
    background: #d7c3ff;
  }
}

#hubspot-messages-iframe-container {
  margin-bottom: 40px;

  @media (max-width: 480px) {
    & > .active + #hubspot-conversations-iframe {
      top: 40px;
    }
  }
}

.guide-modal {
  * {
    font-family: @font-family !important;
  }

  // more than intercom
  z-index: 2147483010 !important;

  .guide-modal-footer-btn {
    background-color: @primary-color;
  }
}

.product-tour-mask {
  &::after {
    top: 0 !important;
    left: 0 !important;
    border: 0 !important;
    border-radius: 0 !important;
  }
}

.upload-in-dropdown {
  .ant-upload {
    display: block;
  }
}

section.test-mode {
  .ant-layout-sider {
    background: #562727;
  }
  .ant-layout-sider-trigger {
    background: #400000;
  }
  .ant-menu.ant-menu-dark {
    background: #562727;
    .ant-menu-inline.ant-menu-sub {
      background: #3f1c1c;
    }
  }
}

.crossed {
  opacity: 0.5;
  transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;

  &:before {
    content: "";
    border-top: 2px solid @dark-blue;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    transform: rotate(-15deg);
  }
}

.modal-curly {
  .ant-modal-content {
    border-radius: 8px;
  }
}

.ant-menu-inline-collapsed .left-menu-upgrade-item {
  padding: 6px !important;

  button {
    padding: 0;
  }
}

.left-menu-upgrade-item svg {
  width: 30px;
  height: 30px;
}

.pillars-modal {
  --border-radius: 10px;

  header {
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    padding: 26px;
    background: linear-gradient(90deg, #00beb3 0%, #00d0a6 46%, #00e58d 100%);
    color: #fff;
  }

  .ant-modal-close-icon {
    color: #fff;
  }

  .ant-modal-content {
    border-radius: var(--border-radius);
  }

  .ant-modal-body {
    padding: 0;
  }

  .modal-inner {
    padding: 36px 36px 24px;
    min-height: 285px;

    .slick-track {
      display: flex;
      align-items: center;
    }
  }

  .check {
    position: absolute;
    top: -12px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  footer {
    padding: 0 36px 36px;
  }
}

@media (min-width: 769px) {
  .ant-row.with-divider {
    .ant-col:not(:last-child) {
      border-right: 1px solid #8c8c8c;
    }
  }
}

.ant-progress-line {
  display: flex;
  align-items: center;

  .ant-progress-text {
    width: auto;
  }
}

.progress-mint-green {
  .ant-progress-inner {
    background-color: #d9d9d9;
  }

  .ant-progress-bg {
    background-color: #83d7ae;
  }

  &.ant-progress-status-success .ant-progress-text {
    color: #83d7ae;
  }
}

.onboarding-accordion {
  background: #fff;

  .ant-collapse-item {
    border: 1px solid #d9d9d9;
    border-radius: 10px !important;
    margin-bottom: 10px;

    .ant-collapse-header {
      padding: 22px;
      padding-right: 36px;

      .ant-collapse-arrow {
        font-size: 16px;
        color: #8c8c8c;
      }
    }

    .ant-collapse-content {
      border-top: none !important;

      .ant-collapse-content-box {
        padding: 0 22px 22px 80px;
      }
    }
  }
}

.onboarding-resources-card {
  h5 {
    transition: color 0.2s ease-in-out;
  }

  img {
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    h5 {
      color: var(--color);
    }

    img {
      transform: scale(1.1);
    }
  }
}

// Common styles for all new-input elements
.new-input {
  // Base styles for all new-input elements and their children
  &,
  &.ant-input-affix-wrapper,
  input,
  .ant-checkbox-inner,
  &.ant-select .ant-select-selector,
  &.ant-input-number,
  .ant-select-clear {
    background-color: transparent !important;
  }

  // Border styles
  &.ant-input-affix-wrapper,
  &:not(.ant-input-password):not(.ant-select)
    input:not(.ant-input-number-input),
  & .ant-checkbox-inner,
  &.ant-select .ant-select-selector,
  &.ant-input-number,
  &.ant-input {
    border: 1px solid @dark-blue;
  }

  // Border radius for specific components
  &.ant-input,
  &.ant-input-affix-wrapper,
  &.ant-input-number {
    border-radius: 15px;

    &.ant-input-status-error {
      background-color: transparent !important;
    }
  }

  // Padding for inputs
  &.ant-input,
  &.ant-input-affix-wrapper,
  &:not(.ant-input-password):not(.ant-select) input {
    padding: 12px 16px;
  }

  // Placeholder styling
  &::placeholder,
  & input::placeholder {
    color: @dark-blue;
    opacity: 0.7;
  }

  // Select component specific styles
  &.ant-select {
    .ant-select-selector {
      border-radius: 15px;
      height: 48px;
      padding: 8px 16px;

      input {
        height: 46px !important;
      }

      .ant-select-selection-placeholder {
        color: @dark-blue;
        opacity: 0.7;
      }
    }

    .ant-select-arrow {
      color: @dark-blue;
    }
  }

  // Checkbox styling
  & .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: @dark-blue;
  }

  // Input number specific styles
  &.ant-input-number input {
    height: auto;
  }
}

.ant-input-group.ant-input-group-compact > .new-input:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.ant-input-group.ant-input-group-compact > .new-input:last-child,
.ant-input-group.ant-input-group-compact
  > .ant-select.new-input:last-child
  > .ant-select-selector {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.new-input {
  .ant-input-number-handler-wrap {
    background: transparent;
  }
}

label.new-input {
  color: @dark-blue;
}

.text-dark-blue {
  color: @dark-blue !important;
}

button.new-primary {
  background-color: #00d0a6;
  border-color: #00d0a6;
  border-radius: 10px;

  &:hover,
  &:focus {
    background-color: darken(#00d0a6, 5%);
    border-color: darken(#00d0a6, 5%);
  }
}

.ant-form-item-explain-error {
  margin-bottom: 6px;
}

// https://github.com/cookpete/react-player?tab=readme-ov-file#responsive-player
.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  .react-player,
  .react-player-loading {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.ant-tooltip {
  z-index: 1000;
}

@font-family: "Lato", "Helvetica Neue", Helvetica, Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";@primary-color: #0077ff;@link-color: #0077ff;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, 0.85);@text-color-secondary: rgba(0, 0, 0, 0.45);@disabled-color: rgba(0, 0, 0, 0.25);@heading-3-size: 20px;@heading-4-size: 16px;@menu-dark-inline-submenu-bg: #1c263f;@layout-header-background: #fff;@layout-header-height: 50px;@layout-header-padding: 0 24px;@dark-blue: #273456;@layout-sider-background: @dark-blue;@menu-dark-bg: @dark-blue;